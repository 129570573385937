<template>
  <div>
    <project-layout :project="project" :project-full="project" :record="record">
      <template v-slot:actions>
        <project-actions
          :project="project"
          :on-assign-manager="onProjectAssignManager"
          :on-delete="onProjectDelete"
          :on-cancel="onProjectCancel"
          :on-export="onProjectExport"
          :on-resume="onProjectResume"
          :on-suspend="onProjectSuspend"
          :on-repair-project="onProjectRepair"
        >
        </project-actions>

        <!-- Jump to -->
        <base-drop-down-menu-button>
          <template v-slot:iconName>
            {{ iconRun }}
          </template>
          <template v-slot:default>
            Jump to
          </template>
          <template v-slot:commands>
            <!-- Jump to associated record in Folders Tab -->
            <base-menu-item
              :to="folderModuleRecordRouteLocation(record)"
              :disabled="!canNavigateToFolderModuleRecord(record)"
            >
              <v-icon :color="menuItemColorClass">
                {{ commandSelectRecordIconName }}
              </v-icon>
              <template v-slot:tooltipContent>
                <div>
                  {{ commandSelectRecordLabel(record) }}
                </div>
              </template>
              {{ commandSelectRecordLabel(record) }}
            </base-menu-item>
          </template>
        </base-drop-down-menu-button>
      </template>

      <template v-slot:alert>
        <base-alert
          v-model="showAlert"
          :dismissible="isAlertDismissible"
          :type="currentAlertType"
        >
          <span v-html="alertMessage"></span>
        </base-alert>
      </template>
    </project-layout>

    <!-- Delete current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.DeleteProject"
      :visible="visibleDialogDeleteProject"
      :perform-action="performProjectDelete"
      @close="closeDialogDeleteProject"
    ></project-operation-dialog>

    <!-- Cancel current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.CancelProject"
      :visible="visibleDialogCancelProject"
      :perform-action="performProjectCancel"
      @close="closeDialogCancelProject"
    ></project-operation-dialog>

    <!-- Suspend current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.SuspendProject"
      :visible="visibleDialogSuspendProject"
      :perform-action="performProjectSuspend"
      @close="closeDialogSuspendProject"
    ></project-operation-dialog>

    <!-- Resume current project dialog -->
    <project-operation-dialog
      :project="project"
      :project-operation="projectOperation.ResumeProject"
      :visible="visibleDialogResumeProject"
      :perform-action="performProjectResume"
      @close="closeDialogResumeProject"
    ></project-operation-dialog>

    <!-- Show Report Dialog -->
    <project-detailed-report-dialog
      :project="project"
      :visible="visibleDialogProjectExport"
      @close="closeDialogExportProject"
    ></project-detailed-report-dialog>

    <!-- Assign Manager Dialog -->
    <assign-manager-dialog
      :project="project"
      :visible="visibleDialogAssignProject"
      :assign-event="onManagerAssignment"
      @close="closeDialogAssignManager"
    ></assign-manager-dialog>

    <!-- snackbar -->
    <snackbar
      v-model="snackbarVisible"
      @close="closeSnackbar"
      :color="snackbarColor"
      :top="snackbarTop"
      >{{ snackbarText }}</snackbar
    >
  </div>
</template>

<script>
// design
import { iconRun } from "@/design/icon/iconConst";

// model
import {
  findRecordType,
  findRecordTypeIcon,
  recordType
} from "@/model/record/recordModel";

// mutation-types
import { UPDATE_PROJECT_MANAGER } from "@/store/shared/mutationTypes/project/project-mutation-types";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapMutations } = createNamespacedHelpers("project");

// mixins
import { projectMixin } from "@/mixins/project/details/projectMixin";
import { folderRecordNavigatable } from "@/mixins/shared/navigatable/folderRecordNavigatable";

export default {
  name: "ProjectDetails",
  inheritAttrs: false,
  mixins: [projectMixin, folderRecordNavigatable],
  components: {
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton")
  },
  data() {
    return {
      iconRun: iconRun
    };
  },
  props: {
    /**
     * project's record
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[]}}
     */
    record: undefined
  },
  computed: {
    /**
     * Select Record Icon Name
     * remarks: overwrite commandSelectRecordIconName
     * @return {string}
     */
    commandSelectRecordIconName() {
      return findRecordTypeIcon(this.record?.recordTypeId ?? recordType.RECORD);
    }
  },
  methods: {
    ...mapMutations({ updateProjectManager: UPDATE_PROJECT_MANAGER }),
    ...mapActions({
      deleteWorkflowProject: "deleteWorkflowProject",
      cancelWorkflowProject: "cancelWorkflowProject",
      suspendWorkflowProject: "suspendWorkflowProject",
      resumeWorkflowProject: "resumeWorkflowProject",
      assignManager: "assignManager"
    }),

    /**
     * perform Delete Project as a callback function
     * @return {Promise<void>}
     */
    async performProjectDelete() {
      await this.deleteWorkflowProject(this.projectId);
    },

    /**
     * perform Cancel Project as a callback function
     * @return {Promise<void>}
     */
    async performProjectCancel() {
      await this.cancelWorkflowProject(this.projectId);
    },

    /**
     * perform Suspend Project as a callback function
     * @param {String} suspensionMessage
     * @return {Promise<void>}
     */
    async performProjectSuspend(suspensionMessage) {
      const payload = {
        id: this.projectId,
        message: suspensionMessage
      };
      await this.suspendWorkflowProject(payload);
    },

    /**
     * perform Resume Project as a callback function
     * @return {Promise<void>}
     */
    async performProjectResume() {
      await this.resumeWorkflowProject(this.projectId);
    },

    /**
     * overwrite command Select Record Label
     * @param {{recordType: string, name: string, recordTypeId: number}} record
     * @return {string}
     */
    commandSelectRecordLabel(record) {
      return `${
        findRecordType(record?.recordTypeId ?? recordType.RECORD)?.name
      }: ${record?.name}`;
    }
  }
};
</script>
